"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsHeuteColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
function settingsHeuteColumnsDefs() {
    return [
        {
            headerName: 'Feld',
            field: 'feldName',
            sortable: true,
            sort: 'asc',
            width: 160,
        },
        {
            headerName: 'Hilfe',
            field: 'feldKey',
            valueFormatter: params => { var _a; return (0, common_ui_1.getHelpTextFromFeldKey)((_a = params.data) === null || _a === void 0 ? void 0 : _a.feldKey); },
            cellRenderer: 'HelpTooltipCell',
            width: 60,
            sortable: true,
        },
        {
            headerName: 'Einstellung',
            field: 'feldKey',
            valueGetter: params => { var _a; return (0, common_ui_1.getHeuteConditionsFromFeldKey)((_a = params.data) === null || _a === void 0 ? void 0 : _a.feldKey); },
            cellRenderer: 'ConditionsCell',
            resizable: true,
            width: 280,
        },
        {
            headerName: 'Empfang',
            field: 'empfang',
            cellStyle: { 'justify-content': 'center' },
            cellRenderer: 'CounterCell',
            resizable: true,
            flex: 1,
            minWidth: 210,
        },
        {
            headerName: 'Prophylaxe',
            field: 'prophylaxe',
            cellStyle: { 'justify-content': 'center' },
            cellRenderer: 'CounterCell',
            resizable: true,
            flex: 1,
            minWidth: 210,
        },
        {
            headerName: 'Zahnarzt',
            field: 'zahnarzt',
            cellStyle: { 'justify-content': 'center' },
            cellRenderer: 'CounterCell',
            resizable: true,
            flex: 1,
            minWidth: 210,
        },
        {
            headerName: 'Abrechnung',
            field: 'abrechnung',
            cellStyle: { 'justify-content': 'center' },
            cellRenderer: 'CounterCell',
            resizable: true,
            flex: 1,
            minWidth: 210,
        },
    ];
}
exports.settingsHeuteColumnsDefs = settingsHeuteColumnsDefs;
