/* unplugin-vue-components disabled */import __unplugin_components_5 from '/builds/rose-metrics/rose-metrics/vui/src/components/settings/Heute/HeuteSonstiges.vue';
import __unplugin_components_4 from '/builds/rose-metrics/rose-metrics/vui/src/components/settings/Heute/HeuteGruppenFilter.vue';
import __unplugin_components_3 from '/builds/rose-metrics/rose-metrics/vui/src/components/settings/Heute/HeuteTerminzeiten.vue';
import __unplugin_components_2 from '/builds/rose-metrics/rose-metrics/vui/src/components/settings/Heute/HeuteAlarmAusschluesse.vue';
import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/tables/settings/Heute/HeuteAlarmTable.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/Helptext.vue';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VCardTitle,[_c('h3',[_vm._v("Darstellung & Alarme")])]),_c(VCardText,[_c(__unplugin_components_0,{attrs:{"text":_vm.helptext}}),_vm._l((_vm.heuteField),function(group){return _c('div',{key:group.name,staticClass:"alarm-group"},[_c(__unplugin_components_1,{attrs:{"items":group.alarme,"title":group.name}})],1)}),_c(__unplugin_components_2)],2)],1),_c(__unplugin_components_3),_c(__unplugin_components_4),_c(__unplugin_components_5,{attrs:{"pvs":_vm.pvs}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }