/* unplugin-vue-components disabled */import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.name))])]}}])},[_c(VCard,[_c(VCardTitle,[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.name))])]),_c(VCardText,[_c('div',{staticClass:"info-popup"},_vm._l((_vm.list),function(item){return _c('div',{key:item,staticClass:"name"},[(item)?_c('span',{staticClass:"name-text"},[_vm._v(_vm._s(item))]):_vm._e()])}),0),(_vm.ohneTerminbehandler && _vm.ohneTerminbehandler.length)?_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"title pb-3",staticStyle:{"white-space":"nowrap"}},[_vm._v("Ohne zugewiesenen Terminbehandler")]),_c('div',{staticClass:"info-popup"},_vm._l((_vm.ohneTerminbehandler),function(item){return _c('div',{key:item,staticClass:"name"},[_c('span',{staticClass:"name-text"},[_vm._v(_vm._s(item))])])}),0)]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }