"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    pvs: {
      type: Object,
      "default": null
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    isCharly: function isCharly() {
      return this.pvs === 'charly';
    },
    klassifizierung: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore_1.editSettingsStore.state.editSettings.heuteSettings) === null || _a === void 0 ? void 0 : _a.klassifizierungBeiPatientAnzeigen;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.updateKlassifizierung(!!value);
      }
    },
    terminstatusTheme: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore_1.editSettingsStore.state.editSettings.heuteSettings) === null || _a === void 0 ? void 0 : _a.terminstatusTheme;
      },
      set: function set(theme) {
        editSettingsStore_1.editSettingsStore.commit.updateTerminstatusTheme(theme);
      }
    },
    weiteAnreise: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore_1.editSettingsStore.state.editSettings.heuteSettings) === null || _a === void 0 ? void 0 : _a.weiteAnreiseInKm;
      },
      set: function set(value) {
        editSettingsStore_1.editSettingsStore.commit.updateWeiteAnreiseInKm(value);
      }
    }
  },
  methods: {}
});