"use strict";

require("core-js/modules/es.object.define-property.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var vue_1 = require("vue");

var editSettingsStore_1 = require("@/state/settings/editSettingsStore");

exports["default"] = (0, vue_1.defineComponent)({
  props: {
    list: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    ohneTerminbehandler: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    updateGruppenFilter: function updateGruppenFilter(data) {
      editSettingsStore_1.editSettingsStore.commit.updateGruppenFilter(data);
    }
  }
});